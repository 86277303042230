import React, { Fragment } from 'react';
import Head from 'next/head';

import Footer from 'components/organisms/Footer';
import Header from 'components/organisms/Header';
import { demoSelectLocationItems } from 'components/molecules/SelectLocation/consts';
import NewsletterBanner from 'components/molecules/NewsletterBanner';
import { LayoutProps } from './types';
import { StyledLayoutMain, StyledLayoutWrapper } from './styles';

const Layout: React.FC<LayoutProps> = ({
  children,
  navItems,
  footerProps,
  newsletterBannerContent,
  hostAtmLinkLabel,
  hostAtmLink,
}) => (
  <Fragment>
    <Head>
      <title>Localcoin | Bitcoin ATM</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
    <StyledLayoutWrapper>
      <Header
        alertMessage="Localcoin will never contact you requesting personal or transaction information through any social platform."
        locations={demoSelectLocationItems}
        {...{
          navItems,
          newsletterBannerContent,
          hostAtmLinkLabel,
          hostAtmLink,
        }}
      />
      <StyledLayoutMain as="main">
        {newsletterBannerContent && (
          <NewsletterBanner>{newsletterBannerContent}</NewsletterBanner>
        )}
        {children}
      </StyledLayoutMain>
      <Footer {...footerProps} items={demoSelectLocationItems} />
    </StyledLayoutWrapper>
  </Fragment>
);

export default Layout;
