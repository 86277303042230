import { ThemeUICSSObject } from 'theme-ui';

export default {
  position: 'relative' as ThemeUICSSObject['position'],
  color: 'white',
  bg: {
    position: 'absolute' as ThemeUICSSObject['position'],
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute' as ThemeUICSSObject['position'],
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage:
        'linear-gradient(45deg, rgba(242, 163, 59, 0.89) 0%, rgba(38, 41, 69, 0.9) 100%)',
    },
  },
};
