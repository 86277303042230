import React from 'react';
import { Global } from '@emotion/react';

import useTheme from 'hooks/useTheme';

const GlobalStyles: React.FC = () => {
  const { transition } = useTheme().transitions.default;

  return (
    <Global
      styles={(theme) => ({
        body: {
          margin: 0,
          fontSize: '1rem',
          letterSpacing: '-0.01em',
          lineHeight: 1.45,
          fontFamily: theme.fonts.body,
          color: theme.colors.text,
          width: '100%',
          overflowX: 'hidden',
          '&.fonts-loading': {
            letterSpacing: '0.03125em',
          },
        },
        button: {
          cursor: 'pointer',
          font: 'inherit',
        },
        a: {
          color: 'currentColor',
          textDecoration: 'none',
          transition,
        },
        svg: {
          '&:not([class])': {
            fill: 'currentColor',
          },
        },
        '.select__menu': {
          width: ['6.5rem !important', '8rem !important'],
        },
      })}
    />
  );
};

export default GlobalStyles;
