import { ThemeUICSSObject } from 'theme-ui';

export default {
  '&[disabled]': {
    opacity: 0.6,
    pointerEvents: 'none' as ThemeUICSSObject['pointerEvents'],
  },
  input: {
    borderWidth: '0 0 1px 0',
    borderStyle: 'solid',
    borderRadius: 0,
    width: '100%',
    p: '0.375rem',
    variant: 'forms.base',
    '&:focus': {
      outline: 'none',
      borderColor: 'texasRose',
    },
  },
};
