declare global {
  interface BooleanConstructor {
    trueString: string;
    falseString: string;
  }
}

const trueString = true.toString();

if (!('trueString' in Boolean)) {
  Object.defineProperty(Boolean, 'trueString', {
    get: (): string => trueString,
  });
}

const falseString = false.toString();

if (!('falseString' in Boolean)) {
  Object.defineProperty(Boolean, 'falseString', {
    get: (): string => falseString,
  });
}

export {};
