import { ThemeUICSSObject } from 'theme-ui';

export default {
  title: {
    variant: 'text.heading1',
    mb: '0.5rem',
  },
  content: {
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
  },
  description: {
    variant: 'text.typo3',
    p: {
      fontWeight: 'inherit',
    },
    a: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  action: {
    mt: '2.5rem',
  },
};
