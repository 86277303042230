export default {
  mx: ['-1.25rem', 'auto', 0],
  width: ['calc(100% + 1.25rem * 2)', '100%', 'auto'],
  navList: {
    alignItems: ['', 'center'],
    justifyContent: ['', '', 'flex-end'],
    flexDirection: ['column', '', 'row'],
    ml: ['', '-1rem', 0],
  },
  linkButton: {
    outline: 'none',
    border: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      color: 'fuelYellow',
      fontSize: '0.375rem',
    },
    variant: 'links.nav',
  },
  hostAtmButton: {
    width: ['calc(100% - 3rem)', '', 'auto'],
    ml: ['1.5rem', '0.5rem'],
    borderTop: '1px solid',
    borderColor: ['mercury', '', 'transparent'],
    py: ['0.625rem', '', 0],
    a: {
      width: ['100%', '', 'auto'],
    },
  },
};
