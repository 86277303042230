import { ThemeUICSSObject } from 'theme-ui';

export default {
  backgroundColor: 'white',
  borderRadius: '0.375rem',
  p: '1.75rem 1.25rem',
  width: '100%',
  color: 'black',
  textAlign: 'center' as ThemeUICSSObject['textAlign'],
  maxWidth: ['22.5rem', '', '100%'],
  borderTop: '8px solid',
  borderColor: 'fuelYellow',
  boxShadow: 'buyBitcoinForm',
  title: {
    mb: '1.25rem',
    fontSize: '1.75rem',
    lineHeight: 1.2,
    fontWeight: 'semiBold',
    borderBottom: '4px solid',
    borderColor: 'fuelYellow',
    pb: '0.25rem',
  },
  poweredBy: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'shuttleGray',
    fontSize: '1.125rem',
    fontWeight: 'semiBold',
    fontFamily: 'secondary',
    mb: '2rem',
  },
  logo: {
    flexShrink: 0,
    ml: '0.375rem',
  },
  info: {
    mt: '2rem',
    a: {
      color: 'fuelYellow',
      '&:hover': {
        color: 'texasRose',
      },
    },
    p: {
      mb: '0.125rem',
      fontSize: '0.625rem',
    },
  },
  action: {
    mt: '1.25rem',
    justifyContent: 'center',
  },
};
