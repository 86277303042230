import { ThemeUICSSObject } from 'theme-ui';
import teaser from './NewsTeaser/theme';

export default {
  title: {
    variant: 'text.heading4',
    mb: '0.625rem',
  },
  tools: {
    flexWrap: 'wrap',
  },
  toolsItem: {
    position: 'relative' as ThemeUICSSObject['position'],
    variant: 'text.text',
    fontFamily: 'secondary',
    mb: '1.5rem',
    text: {
      color: 'fuelYellow',
    },
    '&:not(:last-child)': {
      mr: '1rem',
      '&::after': {
        position: 'absolute' as ThemeUICSSObject['position'],
        top: 0,
        right: '-0.5rem',
        display: 'block',
        content: '"|"',
      },
    },
  },
  content: {
    variant: 'cms',
    fontFamily: 'secondary',
  },
  image: {
    mb: '1.5rem',
    img: {
      width: '100%',
    },
  },
  categories: {
    borderTop: '1px solid',
    borderColor: 'mercury',
    mt: '2rem',
    pt: '0.75rem',
    fontStyle: 'italic',
    variant: 'text.typo11',
    a: {
      color: 'fuelYellow',
    },
  },
  categoriesList: {
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
  categoriesItem: {
    fontStyle: 'italic',
    '&:not(:last-child)': {
      '&::after': {
        content: '","',
        mr: '0.375rem',
      },
    },
  },
  teaser,
};
