import { ThemeUICSSObject } from 'theme-ui';

export default {
  backgroundColor: 'white',
  borderRadius: '0.375rem',
  p: '1.75rem 1.25rem',
  width: '100%',
  color: 'black',
  textAlign: 'left' as ThemeUICSSObject['textAlign'],
  maxWidth: ['22.5rem', '', '100%'],
  borderTop: '8px solid',
  borderColor: 'fuelYellow',
  boxShadow: 'buyBitcoinForm',
  title: {
    mb: '1.25rem',
    fontSize: '1.75rem',
    lineHeight: 1.2,
    pb: '0.25rem',
  },
  action: {
    mt: '1.25rem',
    justifyContent: 'center',
  },
};
