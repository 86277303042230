import { useEffect, useState, useMemo } from 'react';

import useHasMounted from 'hooks/useHasMounted';
import { isServer } from 'utils/nextjs';
import { noop } from 'utils/lodash/client';
import { MediaQueryItem } from './types';

const matchMedia = isServer()
  ? (): MediaQueryItem => ({
      matches: false,
      addListener: noop,
      removeListener: noop,
    })
  : window.matchMedia;

const findMatchingMediaIndex = (mediaQueries: MediaQueryItem[]): number =>
  mediaQueries.findIndex((mql) => mql.matches);

const useMedia = <T>(
  queries: string[],
  values: T[],
  defaultValue: T,
  ssrDefaultValue: T = defaultValue,
): T => {
  const hasMounted = useHasMounted();
  const mediaQueriesList = useMemo(
    () => queries.map((query) => matchMedia(query)),
    // We want to recreate mediaQueries only if one of media queries string
    // changes or order is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queries.join()],
  );
  const [matchIndex, setMatchIndex] = useState(
    findMatchingMediaIndex(mediaQueriesList),
  );

  useEffect(() => {
    const handler = (): void =>
      setMatchIndex(findMatchingMediaIndex(mediaQueriesList));

    mediaQueriesList.forEach((mql) => mql.addListener(handler));

    return (): void =>
      mediaQueriesList.forEach((mql) => mql.removeListener(handler));
  }, [mediaQueriesList]);

  if (!hasMounted) {
    return ssrDefaultValue;
  }

  return typeof values[matchIndex] !== 'undefined'
    ? values[matchIndex]
    : defaultValue;
};

export default useMedia;
