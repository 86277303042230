import { ThemeUICSSObject } from 'theme-ui';

export default {
  position: 'absolute' as ThemeUICSSObject['position'],
  top: '1.875rem',
  right: '-0.5rem',
  transform: 'translateY(-50%)',
  p: '0.5rem',
  width: 'auto',
  height: 'auto',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: 0,
};
