import React, { ButtonHTMLAttributes } from 'react';
import { ButtonProps as ThemedButtonProps } from 'theme-ui';

import { LinkBaseProps } from 'components/atoms/Link/types';

export enum ButtonMode {
  Primary = 'primary',
  Rounded = 'rounded',
  Outlined = 'outlined',
}

export enum ButtonSize {
  Default = 'default',
  Small = 'small',
  Medium = 'medium',
  Big = 'big',
}

export enum ButtonModeType {
  Light = 'light',
  Dark = 'dark',
  Colored = 'colored',
  Transparent = 'transparent',
  TransparentDark = 'transparentDark',
}

export interface ButtonCommonProps {
  mode?: ButtonMode;
  modeType?: ButtonModeType;
  size?: ButtonSize;
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonCommonProps &
  ThemedButtonProps;

export type LinkButtonProps = ButtonCommonProps & LinkBaseProps;

export interface StoryButtonCommonProps extends ButtonCommonProps {
  children?: React.ReactNode;
}
