export default {
  item: {
    pb: '2.5rem',
    mb: '2.5rem',
    borderBottom: '1px solid',
    borderColor: 'mercury',
  },
  nav: {
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'fuelYellow',
    fontFamily: 'secondary',
    pt: '2.5rem',
  },
};
