import { ThemeUICSSObject } from 'theme-ui';

const commonStyles = {
  '&[disabled]': {
    opacity: 0.6,
    pointerEvents: 'none' as ThemeUICSSObject['pointerEvents'],
  },
};

export default {
  default: {
    color: 'inherit',
    '&:hover': {
      color: 'texasRose',
    },
  },
  defaultBold: {
    color: 'inherit',
    fontWeight: 'bold',
  },
  nav: {
    variant: 'text.typo7',
    color: 'text',
    '&:hover': {
      color: ['', '', 'black'],
    },
  },
  colored: {
    color: 'fuelYellow',
    variant: 'text.typo8',
    textDecoration: 'underline',
    '&:hover': {
      color: 'texasRose',
    },
  },
  footer: {
    color: 'dustyGray',
    fontFamily: 'secondary',
    variant: 'text.text',
    ...commonStyles,
    '&:hover': {
      color: 'text',
      textDecoration: 'underline',
    },
  },
  breadcrumbs: {
    color: 'fuelYellow',
    variant: 'text.typo4',
    '&:hover': {
      color: 'texasRose',
      textDecoration: 'underline',
    },
  },
  errorMessage: {
    color: 'inherit',
    variant: 'forms.error',
    textDecoration: 'underline',
    display: 'inline',
  },
  tiny: {
    color: 'black',
    variant: 'text.typo10',
    '&:hover': {
      color: 'fuelYellow',
    },
  },
  big: {
    p: ['0.5rem 0.625rem', '0.5rem 0.75rem', '1rem'],
    color: 'abbey',
    fontSize: '1.125rem',
    '&:hover': {
      color: 'black',
    },
  },
  activeVariants: {
    default: {
      color: 'currentColor',
    },
    big: {
      color: 'black',
      borderBottom: '4px solid',
      borderColor: 'fuelYellow',
    },
  },
};
