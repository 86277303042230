import { ThemeUICSSObject } from 'theme-ui';

const commonStyle = {
  alignItems: 'center',
  gridColumnGap: '2rem',
  gridRowGap: '2rem',
};

export default {
  grid: {
    default: {
      gridTemplateColumns: ['1fr', '', '1fr 22.5rem'],
      textAlign: ['center', '', 'left'],
      ...commonStyle,
    },
    half: {
      gridTemplateColumns: ['1fr', '', 'repeat(2, 1fr)'],
      textAlign: 'center' as ThemeUICSSObject['textAlign'],
      ...commonStyle,
    },
  },
  title: {
    variant: 'text.heading2',
    fontFamily: 'body',
    mb: '0.5rem',
  },
  description: {
    variant: 'text.typo3',
    p: {
      fontWeight: 'inherit',
    },
    a: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  action: {
    justifySelf: ['center', '', 'end'],
  },
};
