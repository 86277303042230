export default {
  '.wrapper': {
    maxWidth: '100%',
  },
  buttons: {
    alignItems: 'center',
    flexDirection: 'column',
    'button, a': {
      '&:not(:last-child)': {
        mb: '0.75rem',
      },
    },
  },
};
