import NextLink from 'next/link';
import React from 'react';
import { Link as ThemedLink } from 'theme-ui';

import { isStorybook } from 'utils/storybook';
import makePathAbsolute from 'utils/makePathAbsolute';
import useTheme from 'hooks/useTheme';
import { scrollToHash } from 'utils/browser';
import { LinkActiveVariant, LinkBaseProps } from './types';
import { tryGetHrefProtocol } from './utils';
import { useIsUrlActive } from './hooks';

const LinkBase: React.FC<LinkBaseProps> = ({
  href,
  children,
  disabled,
  onClick,
  exact = true,
  activeVariant = LinkActiveVariant.Default,
  isActive: isActiveProp,
  locale,
  ...props
}) => {
  const { pathname } = (() => {
    if (href === null || href === undefined) {
      return {
        pathname: null,
      };
    }

    return typeof href === 'string' ? { pathname: href } : href;
  })();
  const pathnameAsString = pathname || '';
  const protocol = tryGetHrefProtocol(pathnameAsString);
  const isExternalLink = protocol && protocol.startsWith('http');
  const isHashLink = pathnameAsString.startsWith('#');
  const modifiedHref =
    !isHashLink && !protocol && !isExternalLink
      ? makePathAbsolute(pathnameAsString)
      : pathnameAsString;
  const shouldRenderAnchor =
    isHashLink || disabled || isExternalLink || protocol || isStorybook();
  const isActive = useIsUrlActive(pathnameAsString, exact);
  const isActiveValue = isActiveProp !== undefined ? isActiveProp : isActive;
  const { activeVariants } = useTheme().links;
  const themeActiveVariant = isActiveValue
    ? activeVariants[activeVariant]
    : undefined;

  if (shouldRenderAnchor) {
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    return (
      <ThemedLink
        href={modifiedHref}
        target={isExternalLink ? '_blank' : undefined}
        rel={isExternalLink ? 'noopener noreferrer' : undefined}
        sx={{ ...themeActiveVariant }}
        onClick={(event) => {
          if (onClick) {
            onClick(event);
          }

          const [, hash] = modifiedHref.split('#');

          scrollToHash(hash);
        }}
        {...{ disabled, ...props }}
      >
        {children}
      </ThemedLink>
    );
  }

  if (pathname === null) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <NextLink {...{ href, locale }} prefetch={false} passHref>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <ThemedLink
        {...{ onClick, disabled, ...props }}
        sx={{ ...themeActiveVariant }}
      >
        {children}
      </ThemedLink>
    </NextLink>
  );
};

export default LinkBase;
