export default {
  variant: 'text.typo4',
  label: {
    alignItems: 'center',
    svg: {
      mr: '0.5rem',
      fontSize: '1.375rem',
    },
  },
  value: {
    ml: '0.375rem',
  },
  notApplicable: {
    color: 'dustyGray',
    fontWeight: 'bold',
  },
};
