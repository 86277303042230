import React from 'react';

import LinkBase from './linkBase';
import { LinkProps } from './types';

const Link: React.FC<LinkProps> = ({ variant, children, ...props }) => (
  <LinkBase {...{ variant, ...props }}>{children}</LinkBase>
);

export default Link;
