export default {
  alignItems: 'center',
  minHeight: '4.625rem',
  image: {
    flexShrink: 0,
    width: '4.625rem',
    mr: '1.25rem',
  },
  title: {
    variant: 'text.heading6',
    fontWeight: 'semiBold',
  },
  description: {
    variant: 'text.text',
    a: {
      color: 'fuelYellow',
      '&:hover': {
        color: 'texasRose',
      },
    },
  },
};
