export default {
  gridTemplateColumns: ['1fr', '', '1fr 25rem'],
  gridColumnGap: '1.5rem',
  alignItems: 'center',
  row: {
    maxWidth: ['', '', '31.25rem'],
    mb: '2rem',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    mb: '0.75rem',
    variant: 'text.heading6',
    svg: {
      color: 'fuelYellow',
      fontSize: '1.5rem',
      mr: '0.75rem',
    },
  },
  map: {
    justifySelf: ['', '', 'end'],
    maxWidth: '20rem',
    mx: ['auto', '', 0],
    width: '100%',
    a: {
      display: 'flex',
      boxShadow: 'locationDetails',
    },
  },
};
