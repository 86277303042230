import { QueryClient, QueryFunction } from 'react-query';

import { makeDefaultQueryFunction } from 'utils/reactQuery';

export const makeReactQueryClient = (): QueryClient =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        queryFn: makeDefaultQueryFunction() as QueryFunction,
      },
    },
  });
