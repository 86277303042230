export interface AccordionItem {
  heading: string | React.ReactNode;
  content: string | React.ReactNode;
  key: string;
  initialState?: boolean;
  onToggle?: (state: boolean) => void;
}

export enum AccordionMode {
  Default = 'default',
  Bordered = 'bordered',
  Simple = 'simple',
}

export type ContentComponentAccordionItem = PartialBy<AccordionItem, 'key'>;

export type ContentComponent = React.ComponentType<{
  item: ContentComponentAccordionItem;
}>;

export interface AccordionItemProps {
  contentComponent: ContentComponent;
  item: ContentComponentAccordionItem;
  mode?: AccordionMode;
}

export interface AccordionContentProps {
  isOpen?: boolean;
}

export interface AccordionProps {
  items: AccordionItem[];
  contentComponent?: ContentComponent;
  makeItemId?: (item: AccordionItem) => string;
  mode?: AccordionMode;
}
