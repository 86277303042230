import { ThemeUICSSObject } from '@theme-ui/css';

export default {
  gridTemplateColumns: ['1fr', '', 'repeat(2, 1fr)'],
  gridColumnGap: ['', '', '3rem'],
  gridRowGap: '2rem',
  alignItems: 'center',
  title: {
    variant: 'text.heading1',
    mb: '0.75rem',
  },
  description: {
    variant: 'text.typo2',
  },
  image: {
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
    gridRow: ['', '', 1],
  },
  content: {
    gridRow: ['', '', 1],
  },
  actions: {
    flexDirection: ['column', 'row'],
    mt: '2rem',
    'button, a': {
      '&:not(:last-child)': {
        mr: ['', '1rem'],
        mb: ['1rem', 0],
      },
    },
  },
};
