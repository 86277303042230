import { QueryFunction } from 'react-query';
import urlJoin from 'url-join';
import { getConfig } from 'utils/config';
import makeRequest from 'utils/makeRequest';
import { isServer } from 'utils/nextjs';

import { MakeMutationFnConfig, MutationFn } from './types';

export const makeApiUrl = (url: string, sellApiRequest = false): string => {
  const {
    publicRuntimeConfig: { sellCryptoApiUrl },
  } = getConfig();

  const host = (() => {
    if (isServer()) {
      return 'http://localhost:3000/';
    }

    return sellApiRequest ? sellCryptoApiUrl : '/';
  })();

  return urlJoin(host, '/api', url);
};

const mutationFunctionsCache: Partial<MappedObject<MutationFn<unknown>>> = {};

export const makeMutationFn = <T, P = unknown>(
  url: string,
  config: MakeMutationFnConfig = {},
): MutationFn<T, P> => {
  const requestMethod = config.method || 'post';
  const cacheKey = `${url}|${requestMethod}`;
  const cachedMutationFunction = mutationFunctionsCache[cacheKey];

  if (cachedMutationFunction) {
    return cachedMutationFunction as MutationFn<T, P>;
  }

  const mutationFnBody: MutationFn<T, P> = async (
    callConfig = {},
  ): Promise<UseMutationResponse<P>> => {
    const { body } = callConfig;
    const { sellApiRequest, ...restConfig } = config;
    const mergedConfig = {
      ...restConfig,
      ...callConfig,
    };

    const response = await makeRequest(
      makeApiUrl(callConfig.url || url, !!sellApiRequest),
      {
        ...mergedConfig,
        headers: {
          'Content-Type': 'application/json',
          ...(mergedConfig.headers || {}),
        },
        method: config.method || 'post',
        body: JSON.stringify(body),
      },
    );

    return {
      response: response as P,
    };
  };

  mutationFunctionsCache[cacheKey] = mutationFnBody as MutationFn<unknown>;

  return mutationFnBody;
};

export const makeDefaultQueryFunction = <T = unknown>(): QueryFunction<
  UseQueryResponse<T>,
  string | [string, RequestInit]
> => async ({ queryKey }) => {
  const {
    publicRuntimeConfig: { sellCryptoApiUrl },
  } = getConfig();

  const [requestPath, requestInit = {}] = Array.isArray(queryKey)
    ? queryKey
    : [queryKey];

  const response = await makeRequest(
    requestPath.startsWith(sellCryptoApiUrl)
      ? requestPath
      : makeApiUrl(requestPath || ''),
    requestInit,
  );

  return {
    response: response as T,
  };
};
