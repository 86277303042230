import getNextConfig from 'next/config';
import { isStorybook } from 'utils/storybook';

import { Config } from './types';

export const getConfig = (): Config => {
  const configObject = isStorybook()
    ? process.env.NEXT_CONFIG
    : getNextConfig();
  const { publicRuntimeConfig, serverRuntimeConfig } = configObject;

  return {
    publicRuntimeConfig: {
      ...publicRuntimeConfig,
      findATMCitiesList: JSON.parse(publicRuntimeConfig.findATMCitiesList),
    },
    serverRuntimeConfig: {
      ...serverRuntimeConfig,
      contactRecipientEmails: (serverRuntimeConfig.contactRecipientEmails || '')
        .split(';')
        .filter((v: string) => !!v),
      hostAtmContactRecipientEmails: (
        serverRuntimeConfig.hostAtmContactRecipientEmails || ''
      )
        .split(';')
        .filter((v: string) => !!v),
      otcRecipientEmails: (serverRuntimeConfig.otcRecipientEmails || '')
        .split(';')
        .filter((v: string) => !!v),
    },
  };
};
