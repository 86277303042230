import { ThemeUICSSObject } from 'theme-ui';

export default {
  gridTemplateColumns: ['1fr', '', 'repeat(2,1fr)'],
  gridRowGap: '2rem',
  gridColumnGap: '2rem',
  content: {
    position: 'relative' as ThemeUICSSObject['position'],
    variant: 'form.content',
    maxWidth: '24rem',
    width: '100%',
    mx: ['auto', '', 0],
    gridRow: 1,
  },
};
