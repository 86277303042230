import React from 'react';
import { Box } from 'theme-ui';
import IconButton from 'components/atoms/Buttons/IconButton';
import { IconCross } from 'components/styles/Icons';
import Inner from 'components/styles/Inner';
import useToggle from 'hooks/useToggle';

const NewsletterBanner: React.FC = ({ children }) => {
  const [newsletterShown, { toggleOff }] = useToggle({
    defaultToggleState: true,
    toggleOnRouteChange: false,
  });

  return newsletterShown ? (
    <Box variant="newsletterBanner">
      <Box variant="newsletterBanner.content">
        <Inner>{children}</Inner>
      </Box>
      <IconButton
        aria-label="Hide announcement banner"
        variant="newsletterBanner.close"
        onClick={toggleOff}
      >
        <IconCross />
      </IconButton>
    </Box>
  ) : null;
};

export default NewsletterBanner;
