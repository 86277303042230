import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import { LinkProps as ThemedLinkProps } from 'theme-ui';
import { UrlObject } from 'url';

export enum LinkVariant {
  Default = 'default',
  DefaultBold = 'defaultBold',
  Nav = 'nav',
  Colored = 'colored',
  Footer = 'footer',
  Breadcrumbs = 'breadcrumbs',
  Tiny = 'tiny',
  Big = 'big',
  ErrorMessage = 'errorMessage',
}

export enum LinkActiveVariant {
  Default = 'default',
  Big = 'big',
}

export interface LinkCommonProps {
  isActive?: boolean;
  disabled?: boolean;
}

export interface LinkBaseProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    ThemedLinkProps,
    LinkCommonProps {
  href: string | UrlObject;
  locale?: string | false;
  activeVariant?: LinkActiveVariant;
  exact?: boolean;
}

export interface LinkProps extends Omit<LinkBaseProps, 'variant'> {
  variant?: LinkVariant;
}

export interface ButtonLinkProps
  extends LinkCommonProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: LinkVariant;
}
