import content from 'components/organisms/ATMDetails/partials/ATMContent/theme';
import slider from 'components/organisms/ATMDetails/partials/ATMSlider/theme';

export default {
  gridTemplateColumns: ['1fr', '', '20rem 1fr'],
  gridColumnGap: '5rem',
  gridRowGap: '2rem',
  content,
  slider,
};
