import { ThemeUICSSObject } from 'theme-ui';

export default {
  textAlign: 'center' as ThemeUICSSObject['textAlign'],
  variant: 'form.card',
  title: {
    mb: '2rem',
    display: 'inline-block',
    variant: 'form.titleAlt',
    textTransform: 'uppercase' as ThemeUICSSObject['textTransform'],
    borderBottom: '4px solid',
    borderColor: 'fuelYellow',
  },
  info: {
    color: 'black',
    fontSize: '0.625rem',
    a: {
      fontWeight: 'semiBold',
    },
    'p + p': {
      mt: '0.75rem',
    },
  },
};
