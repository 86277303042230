import React, { Fragment } from 'react';
import { Box } from 'theme-ui';

import useToggle from 'hooks/useToggle';
import { IconChevronRight } from 'components/styles/Icons';
import Link from 'components/atoms/Link';
import { LinkVariant } from 'components/atoms/Link/types';
import { useIsTablet } from 'hooks/mediaQueries';
import useIsDesktopDevice from 'hooks/useIsDesktopDevice';
import { SubMenuProps, SubMenuItemProps } from './types';
import { StyledSubMenuItem } from './styles';

const SubMenuItem: React.FC<SubMenuItemProps> = ({
  label,
  href,
  items,
  index,
}) => {
  const isDesktopDevice = useIsDesktopDevice();
  const [isOpen, { toggle, registerContainerRef }] = useToggle();
  const isTablet = useIsTablet();

  return (
    <StyledSubMenuItem
      as="li"
      variant="header.subMenu.item"
      className={isOpen ? 'is-open' : undefined}
      ref={isDesktopDevice ? undefined : registerContainerRef}
      {...{ index }}
    >
      {isOpen || !items?.length ? (
        <Link
          variant={LinkVariant.Nav}
          {...{ href }}
          locale={false}
          className="action"
        >
          <IconChevronRight />
          {label}
        </Link>
      ) : (
        <Fragment>
          <Link
            variant={LinkVariant.Nav}
            {...{ href }}
            locale={false}
            className="action"
            onClick={(event) => {
              if (isDesktopDevice || !isTablet) {
                return;
              }

              event.preventDefault();

              toggle();
            }}
          >
            <IconChevronRight />
            {label}
          </Link>
        </Fragment>
      )}
      {items && items?.length > 0 && <SubMenu {...{ items, index }} isNested />}
    </StyledSubMenuItem>
  );
};

const SubMenu: React.FC<SubMenuProps> = ({ items, isNested, index = 0 }) => (
  <Box
    variant="header.subMenu"
    className={isNested ? 'is-nested' : undefined}
    as="ul"
  >
    {items.map(({ label, href, items: subItems }) => (
      <SubMenuItem
        key={label}
        items={subItems}
        index={index + 1}
        {...{ label, href }}
      />
    ))}
  </Box>
);

export default SubMenu;
