import { ThemeUICSSObject } from 'theme-ui';

export default {
  textAlign: 'center' as ThemeUICSSObject['textAlign'],
  title: {
    variant: 'text.heading1',
    mb: '0.75rem',
  },
  description: {
    variant: 'text.text',
    mb: '2.5rem',
    'p + p': {
      mt: '1rem',
    },
  },
};
