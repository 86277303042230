import React, { Fragment, ReactElement } from 'react';
import { ThemeProvider } from '@emotion/react';
import NextApp from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import Head from 'next/head';

import theme from 'setup/theme';
import GlobalStyles from 'setup/GlobalStyles';
import { getConfig } from 'utils/config';
import urljoin from 'url-join';
import Layout from 'components/styles/Layout';
import {
  FOOTER_PROPS_PAGE_PROPS_KEY,
  HEADER_PROPS_PAGE_PROPS_KEY,
} from 'utils/prismic/consts';
import { PageFooterProps, PageHeaderProps } from 'utils/prismic/types';
import { FooterProps } from 'components/organisms/Footer/types';
import { scrollToHash } from 'utils/browser';
import { HeaderProps } from 'components/organisms/Header/types';
import RichTextRenderer from 'components/PageSectionsRenderer/RichTextRenderer';
import { makeReactQueryClient } from './utils';

interface AppProps {
  pageProps: unknown;
}

class LocalCoinApp extends NextApp<AppProps> {
  private queryClient: QueryClient | undefined;

  get headerProps(): Pick<
    HeaderProps,
    'navItems' | 'newsletterBannerContent' | 'hostAtmLink' | 'hostAtmLinkLabel'
  > {
    const { navItems, topBannerContent, hostAtmLink, hostAtmLinkLabel } = (this
      .props.pageProps[HEADER_PROPS_PAGE_PROPS_KEY] || {
      navItems: [],
    }) as PageHeaderProps;

    return {
      navItems,
      newsletterBannerContent: topBannerContent ? (
        <RichTextRenderer content={topBannerContent} />
      ) : undefined,
      hostAtmLink,
      hostAtmLinkLabel,
    };
  }

  get footerProps(): Omit<FooterProps, 'items'> {
    const { columns, disclaimer, copy } = (this.props.pageProps[
      FOOTER_PROPS_PAGE_PROPS_KEY
    ] || { columns: [] }) as PageFooterProps;

    return {
      columns: columns.map(({ content, ...column }) => ({
        ...column,
        content: content ? <RichTextRenderer {...{ content }} /> : undefined,
      })),
      disclaimer,
      copy,
    };
  }

  scrollToCurrentHash = (): void => {
    const [, hash] = this.props.router.asPath.split('#');

    scrollToHash(hash, false);
  };

  componentDidMount(): void {
    const { gtmId } = getConfig().publicRuntimeConfig;

    if (gtmId) {
      import('react-gtm-module').then(({ default: TagManager }) => {
        window.tagManager = TagManager;
        TagManager.initialize({ gtmId });
      });
    }

    this.scrollToCurrentHash();

    this.props.router.events.on(
      'routeChangeComplete',
      this.scrollToCurrentHash,
    );
  }

  render(): ReactElement<unknown> {
    const { Component, pageProps, router } = this.props;
    const { appDomain } = getConfig().publicRuntimeConfig;
    const [pathname] = router.asPath.split('#');

    if (!this.queryClient) {
      this.queryClient = makeReactQueryClient();
    }

    return (
      <Fragment>
        <Head>
          {router.isPreview ? (
            <script
              async
              defer
              src={getConfig().publicRuntimeConfig.prismicPreviewJsUrl}
            />
          ) : null}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap"
            rel="stylesheet"
          />
          <meta
            name="og:image"
            content={urljoin(appDomain, '/assets/images/machine-coins.png')}
          />
        </Head>
        <QueryClientProvider client={this.queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ThemeProvider {...{ theme }}>
              <GlobalStyles />
              <Layout {...this.headerProps} footerProps={this.footerProps}>
                <Component key={pathname} {...pageProps} />
              </Layout>
            </ThemeProvider>
          </Hydrate>
        </QueryClientProvider>
      </Fragment>
    );
  }
}

export default LocalCoinApp;
