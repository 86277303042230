export default {
  title: {
    variant: 'text.heading1',
    mb: '0.75rem',
  },
  description: {
    columnGap: ['', '', '2.5rem'],
    columnCount: [1, '', 2],
    variant: 'text.text',
    mb: '2.5rem',
    'p + p': {
      mt: '1rem',
    },
  },
};
