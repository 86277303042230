export default {
  image: {
    display: 'block',
    variant: 'news.image',
    '&:hover': {
      opacity: 0.85,
    },
    img: {
      width: '100%',
    },
  },
  readMore: {
    display: 'block',
    mt: '1rem',
    variant: 'text.text',
    fontFamily: 'secondary',
    color: 'fuelYellow',
  },
};
