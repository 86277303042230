import { ThemeUICSSObject } from 'theme-ui';

export default {
  lines: {
    width: '1.375rem',
    height: '1.375rem',
    position: 'relative' as ThemeUICSSObject['position'],
    span: {
      height: '2px',
      width: '100%',
      backgroundColor: 'black',
      position: 'absolute' as ThemeUICSSObject['position'],
      opacity: 1,
      left: '50%',
      top: '50%',
      variant: 'transitions.default',
      '&:first-of-type': {
        transform: 'translate(-50%, -50%) translateY(-7px)',
      },
      '&:nth-of-type(2)': {
        transform: 'translate(-50%, -50%)',
      },
      '&:last-child': {
        transform: 'translate(-50%, -50%) translateY(7px)',
      },
    },
  },
  linesOpen: {
    variant: 'hamburger.lines',
    span: {
      variant: 'hamburger.lines.span',
      '&:first-of-type': {
        transform: 'translate(-50%, -50%) rotate(45deg)',
      },
      '&:nth-of-type(2)': {
        opacity: 0,
      },
      '&:last-child': {
        transform: 'translate(-50%, -50%) rotate(-45deg)',
      },
    },
  },
  label: {
    m: 0,
    minWidth: 0,
    position: 'absolute' as ThemeUICSSObject['position'],
    opacity: 0,
    zIndex: -1,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
  },
};
