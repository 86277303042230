import styled from '@emotion/styled';

import { Box } from 'theme-ui';
import media from 'setup/MediaQueries';
import { StyledSubMenuItemProps } from './types';

export const StyledSubMenuItem = styled(Box)<StyledSubMenuItemProps>`
  .action {
    ${({ index = 0 }) => `padding-left: ${index + 1}rem`};
    @media ${media.tablet} {
      padding-left: 1.5rem;
    }
  }
`;
