export default {
  row: {
    '&:not(:last-child)': {
      mb: '2rem',
    },
  },
  description: {
    variant: 'cms',
    fontSize: '0.875rem',
    ml: '-1.5rem',
  },
};
