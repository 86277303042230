export const findKeyByValue = <T>(
  obj: T,
  vaueToFind: T[keyof T],
): string | undefined => {
  const entry = Object.entries(obj).find(([_, value]) => value === vaueToFind);

  return entry?.[0];
};

export const removeUndefinedFields = <T>(obj: T): T =>
  JSON.parse(JSON.stringify(obj));

export const deepCloneObject = removeUndefinedFields;

export const getEnumValueByStringValue = <T>(
  enumObj: T,
  value: string,
): T[keyof T] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyEnumObj = enumObj as any;
  const result = Object.entries(anyEnumObj).find(
    ([_, enumValue]) => String(enumValue) === value,
  );
  const [key] = result || [];

  return key ? anyEnumObj[key] : undefined;
};

export const replaceAll = (
  text: string,
  textToReplace: string,
  replacement: string,
): string => text.split(textToReplace).join(replacement);

export const ensureTrailingSlash = (text: string): string =>
  text.endsWith('/') ? text : `${text}/`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
export const noop = (..._: any[]): any => {};

export const upperFirst = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);
