/** @jsxImportSource theme-ui */
import React from 'react';
import { IconButton as ThemedIconButton } from 'theme-ui';

import useTheme from 'hooks/useTheme';
import { IconButtonProps } from './types';

const IconButton: React.FC<IconButtonProps> = ({ ...props }) => {
  const { ...rest } = useTheme().buttons;

  return <ThemedIconButton variant="icon" {...props} sx={{ ...rest }} />;
};

export default IconButton;
