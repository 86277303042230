import { ThemeUICSSObject } from 'theme-ui';

export default {
  textAlign: ['center', '', 'left'] as ThemeUICSSObject['textAlign'],
  gridTemplateColumns: ['1fr', '', '1fr 19.5rem 18.75rem', '1fr 1fr 18.75rem'],
  gridColumnGap: ['', '', '2.5rem', '3rem'],
  gridRowGap: '2rem',
  details: {
    a: {
      display: 'block',
      variant: 'text.text',
      color: 'fuelYellow',
      mb: '0.5rem',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  name: {
    variant: 'text.heading6',
    fontWeight: 'semiBold',
    mb: '0.5rem',
  },
  address: {
    variant: 'text.typo5',
    mb: '0.5rem',
  },
  media: {
    display: 'block',
    alignSelf: ['', '', 'center'],
    maxWidth: ['18.75rem', '', '100%'],
    mx: ['auto', '', 0],
    width: ['100%', '', 'auto'],
    a: {
      display: 'block',
    },
  },
  actions: {
    alignItems: ['center', '', 'flex-start'],
    flexDirection: 'column',
    'button, a': {
      '&:not(:last-child)': {
        mb: '1rem',
      },
    },
  },
  openHours: {
    fontFamily: 'secondary',
    strong: {
      fontWeight: 'semiBold',
    },
  },
  column: {
    gridRowGap: ['1rem', '', '2.5rem'],
  },
  currencyLabels: {
    justifyContent: ['center', '', 'start'],
    gridRowGap: '0.5rem',
  },
};
