import { Box } from 'theme-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import media from 'setup/MediaQueries';
import { StyledMainNavProps } from './types';
// import { STICKY_HEADER_CLASS } from './consts';

export const StyledHeader = styled(Box)`
  position: relative;
  transition: opacity 170ms ease-in-out, visibility 170ms ease-in-out;
  z-index: ${({ theme }) => theme.zIndices.header};
  border-bottom: 1px solid rgba(58, 58, 58, 0.13);

  @media ${media.tablet} {
    .is-sticky & {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }
`;

export const StyledStickyHeader = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  transition: opacity 170ms ease-in-out, visibility 170ms ease-in-out;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: ${({ theme }) => theme.zIndices.stickyHeader};

  @media ${media.tablet} {
    .is-sticky & {
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
    }
  }
`;

export const StyledMainNav = styled(Box)<StyledMainNavProps>`
  opacity: 0;
  width: 100%;
  overflow: hidden;
  transition: max-height 700ms ease-out, opacity 270ms ease;
  ${({ isOpen }) =>
    css`
      opacity: 1;
      max-height: ${isOpen ? '2999px' : 0};
      transition: max-height 0.25s ease-in;
    `}

  @media ${media.tablet} {
    max-height: unset;
  }
`;
