import { ThemeUICSSObject } from 'theme-ui';

export default {
  wrapper: {
    paddingY: ['2rem', '', '3.75rem'],
  },
  title: {
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
    mb: '1.75rem',
    variant: 'text.heading1',
    '.fonts-loading &': {
      letterSpacing: '-0.01em',
    },
  },
  description: {
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
    mb: '2rem',
    variant: 'text.typo5',
  },
  action: {
    justifyContent: 'center',
    mt: '2.5rem',
  },
};
