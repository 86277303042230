import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button, Flex, Box } from 'theme-ui';

import { transition } from 'utils/mixins';
import media from 'setup/MediaQueries';
import Link from 'components/atoms/Link';
import { StyledNavLinkProps, StyledNavSubMenuProps } from './types';

const navStyle = () =>
  css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.75rem 1.5rem;

    @media ${media.tablet} {
      width: auto;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  `;

const subMenuStyle = () => css`
  overflow: hidden;

  @media ${media.tablet} {
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

export const StyledNavList = styled(Flex)`
  -webkit-overflow-scrolling: touch;
`;

export const StyledNavLink = styled(Link)<StyledNavLinkProps>`
  ${navStyle}

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.colors.black};
    `}
`;

export const StyledNavButton = styled(Button)<StyledNavLinkProps>`
  ${navStyle}
  color: currentColor;
  border: none;
  font-family: inherit;
  background: transparent;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.colors.black};
    `}
`;

export const StyledNavMobileWrapper = styled(Flex)`
  .header-nav-button,
  .header-nav-link {
    text-align: left;
    justify-content: flex-start;
  }

  @media ${media.tablet} {
    align-items: center;
  }
`;

export const StyledNavSubMenu = styled(Box)<StyledNavSubMenuProps>`
  ${transition({ property: 'max-height' })}
  max-height: ${({ isOpen }) => (isOpen ? '999px' : 0)};
  ${subMenuStyle}

  @media ${media.tablet} {
    overflow: unset;
    pointer-events: none;
    opacity: 0;
    ${transition({ property: 'opacity' })}
  }
`;

export const StyledNavSubMenuMobile = styled(Box)<StyledNavSubMenuProps>`
  ${transition({ property: 'max-height' })}

  width: 100%;
  max-height: ${({ isOpen }) => (isOpen ? '999px' : 0)};
  ${subMenuStyle}

  @media ${media.phone} {
    width: auto;
    overflow: ${({ isOpen }) => (isOpen ? 'unset' : 'hidden')};
  }
`;

export const StyledNavItem = styled.li`
  position: relative;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.mercury};

  @media ${media.tablet} {
    width: auto;
    border: none;
    &:hover {
      .header-nav-sub-menu {
        opacity: 1;
        max-height: 999px;
        pointer-events: auto;
      }
    }
  }
`;

export const StyledNavItemMobile = styled.li``;
