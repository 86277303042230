import { ThemeUICSSObject } from 'theme-ui';

export default {
  width: '100%',
  height: 'auto',
  button: {
    position: 'absolute' as ThemeUICSSObject['position'],
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    p: 0,
    backgroundColor: 'transparent',
    zIndex: 1,
    variant: 'transitions.default',
    color: 'fuelYellow',
    svg: {
      fontSize: '4rem',
    },
    '&:hover': {
      color: 'texasRose',
    },
  },
  placeholderImage: {
    position: 'relative' as ThemeUICSSObject['position'],
  },
};
