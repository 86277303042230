export default {
  title: {
    variant: 'text.heading6',
    mb: ['1.25rem', '1.5rem'],
  },
  content: {
    flexWrap: ['wrap', 'nowrap'],
    input: {
      height: '2.625rem',
    },
  },
  boxed: {
    background: 'white',
    color: 'black',
    padding: '2rem 2rem',
    border: '1px solid #e6e6e6',
  },
  successMessageBoxed: {
    position: 'relative',
    background: 'white',
    borderRadius: '1rem',
    padding: '2rem 2rem',
  },
  action: {
    flexShrink: 0,
    ml: ['', '1rem'],
    mt: ['1rem', 0],
    width: ['100%', 'auto'],
    button: {
      width: ['100%', 'auto'],
    },
  },
};
