import { ThemeUICSSObject } from 'theme-ui';

export default {
  variant: 'text.text',
  fontFamily: 'secondary',
  a: {
    color: 'fuelYellow',
  },
  date: {
    textTransform: 'uppercase' as ThemeUICSSObject['textTransform'],
  },
  list: {
    p: '0 1.25rem 1.25rem',
    border: '1px solid',
    borderColor: 'mercury',
  },
  item: {
    pt: '1.25rem',
    '&:not(:last-child)': {
      borderBottom: '1px solid',
      borderColor: 'mercury',
      pb: '1.25rem',
    },
  },
};
