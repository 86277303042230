import routes from 'setup/consts/routes';
import { LOCALES } from '../../../../config/next/languages';
import { SelectLocationItem } from './types';

export const demoSelectLocationItems: SelectLocationItem[] = [
  {
    key: '1',
    label: 'Canada',
    href: routes.home,
    src: '/assets/images/canadian-flag.png',
    alt: 'Canada',
    locale: LOCALES.ca,
  },
  {
    key: '2',
    label: 'United States',
    href: routes.home,
    src: '/assets/images/american-flag.png',
    alt: 'United States',
    locale: LOCALES.us,
  },
  {
    key: '3',
    label: 'Québec',
    href: routes.home,
    src: '/assets/images/quebec-flag.png',
    alt: 'Québec',
    locale: LOCALES.fr,
  },
];
