export default {
  base: {
    variant: 'text.text',
    a: {
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  default: {
    color: 'text',
    variant: 'message.base',
  },
  error: {
    color: 'error',
    variant: 'message.base',
  },
  success: {
    color: 'malachite',
    variant: 'message.base',
  },
};
