/** @jsxImportSource theme-ui */
import React from 'react';

import useTheme from 'hooks/useTheme';
import {
  ButtonProps,
  ButtonMode,
  LinkButtonProps,
  ButtonModeType,
  ButtonSize,
  ButtonCommonProps,
} from './types';
import { StyledButton, StyledFakeButton, StyledLinkButton } from './styles';

const Button: React.FC<ButtonProps> = ({
  mode = ButtonMode.Primary,
  modeType = ButtonModeType.Light,
  size = ButtonSize.Default,
  ...props
}) => {
  const { modes, sizes, ...rest } = useTheme().buttons;
  const themeMode = modes[mode];
  const themeModeType =
    'modeTypes' in themeMode ? themeMode.modeTypes[modeType] : {};

  return (
    <StyledButton
      {...props}
      sx={{
        ...rest,
        ...themeMode,
        ...themeModeType,
        ...sizes[size],
      }}
    />
  );
};

const LinkButton: React.FC<LinkButtonProps> = ({
  mode = ButtonMode.Primary,
  modeType = ButtonModeType.Light,
  size = ButtonSize.Default,
  ...props
}) => {
  const { modes, sizes, ...rest } = useTheme().buttons;
  const themeMode = modes[mode];
  const themeModeType =
    'modeTypes' in themeMode ? themeMode.modeTypes[modeType] : {};

  return (
    <StyledLinkButton
      {...props}
      sx={{
        ...rest,
        ...themeMode,
        ...themeModeType,
        ...sizes[size],
      }}
    />
  );
};

const FakeButton: React.FC<ButtonCommonProps> = ({
  mode = ButtonMode.Primary,
  modeType = ButtonModeType.Light,
  size = ButtonSize.Default,
  children,
}) => {
  const { modes, sizes, ...rest } = useTheme().buttons;
  const themeMode = modes[mode];
  const themeModeType =
    'modeTypes' in themeMode ? themeMode.modeTypes[modeType] : {};

  return (
    <StyledFakeButton
      sx={{
        ...rest,
        ...themeMode,
        ...themeModeType,
        ...sizes[size],
      }}
    >
      {children}
    </StyledFakeButton>
  );
};

export { LinkButton, FakeButton, Button as default };
