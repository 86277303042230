export const scrollToHash = (hash: string, smooth = true): void => {
  const element = document.querySelector(`[data-hashscroll-id="${hash}"]`);

  if (!element) {
    return;
  }

  element.scrollIntoView(
    smooth
      ? {
          behavior: 'smooth',
        }
      : undefined,
  );
};

export const copyTextToClipboard = (text: string): void => {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(text);
  } else {
    document.execCommand('copy', true, text);
  }
};

export const scrollTo = (
  x: number,
  y: number,
  behavior: ScrollBehavior = 'smooth',
): void => {
  if (typeof window === 'undefined') {
    return;
  }

  window.scrollTo({
    behavior,
    left: x,
    top: y,
  });
};
