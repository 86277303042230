import React from 'react';
import { Container } from 'theme-ui';
import { InnerProps } from './types';

const Inner: React.FC<InnerProps> = ({ isWide, children, hydrated }) => (
  <Container data-hydrated={hydrated} variant={isWide ? 'innerWide' : 'inner'}>
    {children}
  </Container>
);

export default Inner;
