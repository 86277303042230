export default {
  width: '100%',
  '.swiper-container': {
    display: 'flex',
    overflow: 'hidden',
    cursor: 'grab',
    height: '5.625rem',
  },
  '.swiper-wrapper': {
    display: 'flex',
    alignItems: 'center',
  },
  '.swiper-slide': {
    display: 'flex',
    flexShrink: 0,
    '> div': {
      width: '100%',
    },
  },
  wrapper: {
    display: ['', 'none'],
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    scrollBehavior: 'smooth',
  },
  list: {
    alignItems: 'center',
  },
  item: {
    willChange: 'scroll-position',
    display: 'flex',
    flexShrink: 0,
    maxWidth: '11.25rem',
    mx: '1.25rem',
    maxHeight: ['', '5.625rem'],
    img: {
      flexShrink: 0,
    },
  },
  swiper: {
    display: ['none', 'block'],
    filter: 'grayscale(100%)',
    opacity: 0.8,
  },
};
