export default {
  gridTemplateColumns: ['1fr', '', 'repeat(3, 1fr)'],
  gridColumnGap: '1.5rem',
  gridRowGap: '1.5rem',
  item: {
    width: '100%',
    maxWidth: '20rem',
    margin: '0 auto',
  },
};
