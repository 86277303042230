import { ThemeUICSSObject } from 'theme-ui';

export default {
  position: 'relative' as ThemeUICSSObject['position'],
  variant: 'form.content',
  info: {
    variant: 'text.text',
    mb: ['0.75rem', '', '1.25rem'],
  },
};
