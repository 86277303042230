import React, { Fragment, useCallback } from 'react';
import { Box } from 'theme-ui';

import useToggle from 'hooks/useToggle';
import { useIsTablet } from 'hooks/mediaQueries';
import { useIsUrlActive } from 'components/atoms/Link/hooks';
import { LinkVariant } from 'components/atoms/Link/types';
import { LinkButton } from 'components/atoms/Buttons/Button';
import {
  ButtonMode,
  ButtonModeType,
  ButtonSize,
} from 'components/atoms/Buttons/Button/types';
import useIsDesktopDevice from 'hooks/useIsDesktopDevice';
import {
  StyledNavList,
  StyledNavItem,
  StyledNavLink,
  StyledNavMobileWrapper,
  StyledNavSubMenu,
  StyledNavButton,
  StyledNavSubMenuMobile,
} from './styles';
import SubMenu from '../SubMenu';
import { NavItemProps, NavProps } from './types';

const NavItem: React.FC<NavItemProps> = React.memo(({ item }) => {
  const isDesktopDevice = useIsDesktopDevice();
  const isTablet = useIsTablet();
  const [isOpen, { toggle, registerContainerRef }] = useToggle({
    defaultToggleState: item.initialState,
    toggleOnRouteChange: false,
  });

  const toggleItem = useCallback(() => {
    toggle();

    if (item.onToggle) {
      item.onToggle(!isOpen);
    }
  }, [isOpen, item, toggle]);

  const isActive = useIsUrlActive(
    [item.href, ...(item.subMenu || []).map(({ href }) => href)],
    item.exact,
  );

  const NavButtonElement = (
    <StyledNavButton
      {...{ isActive }}
      type="button"
      aria-label={`Open ${item.label}`}
      variant="header.nav.linkButton"
      className="header-nav-button"
    >
      {item.label}
    </StyledNavButton>
  );

  const navMobileWrapperElement = (
    <StyledNavMobileWrapper onClick={toggleItem}>
      {isOpen || !item.subMenu?.length ? (
        <StyledNavLink
          {...{ isActive }}
          href={item.href}
          exact={item.exact}
          variant={LinkVariant.Nav}
          className="header-nav-link"
        >
          {item.label}
        </StyledNavLink>
      ) : (
        NavButtonElement
      )}
    </StyledNavMobileWrapper>
  );

  const navElement = (
    <Fragment>
      {isDesktopDevice ? (
        <Fragment>
          {isTablet ? (
            <StyledNavLink
              {...{ isActive }}
              href={item.href}
              exact={item.exact}
              variant={LinkVariant.Nav}
              locale={false}
              className="header-nav-link"
            >
              {item.label}
            </StyledNavLink>
          ) : (
            navMobileWrapperElement
          )}
        </Fragment>
      ) : (
        navMobileWrapperElement
      )}
    </Fragment>
  );

  return (
    <Fragment>
      {navElement}
      {isDesktopDevice ? (
        <Fragment>
          {item.subMenu && (
            <StyledNavSubMenu className="header-nav-sub-menu" {...{ isOpen }}>
              <SubMenu items={item.subMenu} />
            </StyledNavSubMenu>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {isOpen && item.subMenu && (
            <StyledNavSubMenuMobile
              ref={isTablet ? registerContainerRef : undefined}
              {...{ isOpen }}
            >
              <SubMenu items={item.subMenu} />
            </StyledNavSubMenuMobile>
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

const Nav: React.FC<NavProps> = React.memo(
  ({ items, hostAtmLink, hostAtmLinkLabel }) => (
    <Box as="nav" variant="header.nav">
      <StyledNavList variant="header.nav.navList" as="ul">
        {items.map((item) => (
          <StyledNavItem key={item.key}>
            <NavItem {...{ item }} />
          </StyledNavItem>
        ))}
        <Box as="li" variant="header.nav.hostAtmButton">
          {hostAtmLink && (
            <LinkButton
              mode={ButtonMode.Rounded}
              size={ButtonSize.Small}
              modeType={ButtonModeType.Colored}
              href={hostAtmLink}
              locale={false}
            >
              {hostAtmLinkLabel}
            </LinkButton>
          )}
        </Box>
      </StyledNavList>
    </Box>
  ),
);

export default Nav;
