export default {
  justifyContent: 'center',
  flexWrap: 'wrap',
  width: 'calc(100% + 2.5rem)',
  mx: '-1.25rem',
  mt: '-1rem',
  item: {
    maxWidth: '11.25rem',
    mx: '1.25rem',
    mt: '1rem',
  },
};
