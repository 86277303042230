import { ThemeUICSSObject } from 'theme-ui';

const subMenuStyle = {
  borderTop: ['', '', '3px solid'],
  borderColor: ['', '', 'dustyGray'],
  backgroundColor: 'white',
  boxShadow: ['', '', 'subMenu'],
};

const itemStyle = {
  position: 'relative' as ThemeUICSSObject['position'],
  minWidth: ['100%', '', '13.5rem', '14.5rem'],
  borderTop: ['1px solid', '', 'none'],
  borderColor: 'mercury',
  svg: {
    display: ['', '', 'none'],
    mr: '0.75rem',
    fontSize: '0.5rem',
  },
  button: {
    backgroundColor: 'transparent',
  },
  '.action': {
    p: ['0.75rem 1rem 0.75rem 2rem', '', '0.75rem 1rem'],
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left' as ThemeUICSSObject['textAlign'],
  },
};

const positionSubMenuStyle = {
  position: ['', '', 'absolute'],
  left: ['', '', '100%'],
};

export default {
  py: ['', '', '0.25rem'],
  ...subMenuStyle,
  '&.is-nested': {
    ...subMenuStyle,
    ...positionSubMenuStyle,
    top: ['', '', '-0.4375rem'],
    variant: 'transitions.default',
    opacity: ['', '', 0],
    pointerEvents: ['', '', 'none'] as ThemeUICSSObject['pointerEvents'],
  },
  item: {
    ...itemStyle,
    '&:hover, &.is-open': {
      '> .is-nested': {
        opacity: ['', '', 1],
        pointerEvents: ['', '', 'auto'] as ThemeUICSSObject['pointerEvents'],
      },
    },
  },
  linkButton: {
    justifyContent: 'flex-start',
    fontFamily: 'inherit',
    variant: 'links.nav',
  },
  button: {
    variant: 'text.typo7',
    color: 'text',
    '&:hover': {
      color: ['', '', 'black'],
    },
  },
};
