import { ThemeUICSSObject } from 'theme-ui';

export default {
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center' as ThemeUICSSObject['textAlign'],
  number: {
    fontSize: '3.75rem',
    fontWeight: 'semiBold',
    mt: '0.75rem',
  },
  title: {
    mb: '2rem',
    variant: 'text.typo4',
    fontWeight: 'semiBold',
  },
};
