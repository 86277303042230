import { StoreItem } from './types';

export const ATMS_LIST_HEIGHT = 436;

export const demoStoresListing: StoreItem[] = [
  {
    key: '1',
    name: 'Dépanneur Sunny 1',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
    storeHref:
      '/bitcoin-atm/quebec/montreal/d%C3%A9panneur_sunny-at-886-rue-jarry-w/',
  },
  {
    key: '2',
    name: 'Dépanneur Sunny 2',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
    storeHref:
      '/bitcoin-atm/quebec/montreal/d%C3%A9panneur_sunny-at-886-rue-jarry-w/',
  },
  {
    key: '3',
    name: 'Dépanneur Sunny 3',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
    storeHref:
      '/bitcoin-atm/quebec/montreal/d%C3%A9panneur_sunny-at-886-rue-jarry-w/',
  },
  {
    key: '4',
    name: 'Dépanneur Sunny 4',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
    storeHref:
      '/bitcoin-atm/quebec/montreal/d%C3%A9panneur_sunny-at-886-rue-jarry-w/',
  },
  {
    key: '5',
    name: 'Dépanneur Sunny 5',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
    storeHref:
      '/bitcoin-atm/quebec/montreal/d%C3%A9panneur_sunny-at-886-rue-jarry-w/',
  },
  {
    key: '6',
    name: 'Dépanneur Sunny 6',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
    storeHref:
      '/bitcoin-atm/quebec/montreal/d%C3%A9panneur_sunny-at-886-rue-jarry-w/',
  },
  {
    key: '7',
    name: 'Dépanneur Sunny 7',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
    storeHref:
      '/bitcoin-atm/quebec/montreal/d%C3%A9panneur_sunny-at-886-rue-jarry-w/',
  },
];

export const demoStoresWithoutDetailsLinkListing: StoreItem[] = [
  {
    key: '1',
    name: 'Dépanneur Sunny 1',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
  },
  {
    key: '2',
    name: 'Dépanneur Sunny 2',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
  },
  {
    key: '3',
    name: 'Dépanneur Sunny 3',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
  },
  {
    key: '4',
    name: 'Dépanneur Sunny 4',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
  },
  {
    key: '5',
    name: 'Dépanneur Sunny 5',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
  },
  {
    key: '6',
    name: 'Dépanneur Sunny 6',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
  },
  {
    key: '7',
    name: 'Dépanneur Sunny 7',
    location: '886 Rue Jarry W Montreal, H3N 1G9, Canada',
    actionHref: 'https://maps.google.com/maps?cid=12439295953798226692',
  },
];
