import { ThemeUICSSObject } from 'theme-ui';

export default {
  heading: {
    mb: '1.5rem',
    textAlign: ['center', '', 'left'],
  },
  title: {
    variant: 'text.heading3',
    mb: '0.375rem',
  },
  info: {
    variant: 'text.text',
    a: {
      fontWeight: 'semiBold',
      color: 'lochmara',
    },
  },
  content: {
    mt: '2rem',
    overflowX: ['auto', 'unset'],
    WebkitOverflowScrolling: 'touch',
    scrollBehavior: 'smooth',
  },
  table: {
    width: ['', '100%'],
    borderCollapse: 'collapse',
    head: {
      textAlign: 'left' as ThemeUICSSObject['textAlign'],
      color: 'white',
      backgroundColor: 'fuelYellow',
      variant: 'text.text',
      th: {
        p: '0.5rem',
      },
    },
    body: {
      td: {
        verticalAlign: 'middle',
        border: '1px solid',
        borderColor: 'mercury',
        p: '0.5rem',
        variant: 'text.text',
        strong: {
          fontWeight: 'semiBold',
        },
        a: {
          color: 'black',
          fontWeight: 'semiBold',
          '&:hover': {
            color: 'text',
          },
        },
      },
    },
    column: {
      backgroundColor: 'alabaster',
    },
  },
};
