import styled from '@emotion/styled';
import { Button } from 'theme-ui';
import { css } from '@emotion/react';

import LinkBase from 'components/atoms/Link/linkBase';

const commonStyles = css`
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  display: flex;
  font-family: inherit;
  ${commonStyles}
`;

export const StyledLinkButton = styled(LinkBase)`
  display: inline-flex;
  ${commonStyles}
`;

export const StyledFakeButton = styled.span`
  display: inline-flex;
  ${commonStyles}
`;
