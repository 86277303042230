import { ThemeUICSSObject } from 'theme-ui';

export default {
  variant: 'transitions.default',
  lineHeight: 1,
  '&[disabled]': {
    opacity: 0.6,
    pointerEvents: 'none' as ThemeUICSSObject['pointerEvents'],
  },
  modes: {
    primary: {
      borderRadius: 'medium',
      fontWeight: 'semiBold',
      textTransform: 'uppercase' as ThemeUICSSObject['textTransform'],
      svg: {
        mr: '0.5rem',
      },
      modeTypes: {
        light: {
          color: 'black',
          backgroundColor: 'white',
          '&:hover': {
            color: 'white',
            backgroundColor: 'rgba(238, 238, 238, 0.3)',
          },
        },
        colored: {
          color: 'ebonyClay',
          backgroundColor: 'fuelYellow',
          '&:hover': {
            backgroundColor: 'texasRose',
          },
        },
        dark: {
          color: 'white',
          backgroundColor: 'charade',
          '&:hover': {
            backgroundColor: 'shuttleGray',
          },
        },
        transparent: {
          color: 'black',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'rgba(238, 238, 238, 0.3)',
          },
        },
        transparentDark: {},
      },
    },
    rounded: {
      borderRadius: 'medium',
      fontWeight: 'semiBold',
      svg: {
        mr: '0.5rem',
      },
      modeTypes: {
        light: {
          color: 'black',
          backgroundColor: 'white',
          '&:hover': {
            color: 'white',
            backgroundColor: 'rgba(238, 238, 238, 0.3)',
          },
        },
        colored: {
          color: 'ebonyClay',
          backgroundColor: 'fuelYellow',
          '&:hover': {
            backgroundColor: 'texasRose',
          },
        },
        dark: {
          color: 'white',
          backgroundColor: 'ebonyClay',
          '&:hover': {
            backgroundColor: 'shuttleGray',
          },
        },
        transparent: {
          color: 'black',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'rgba(238, 238, 238, 0.3)',
          },
        },
        transparentDark: {},
      },
    },
    outlined: {
      borderRadius: 'medium',
      border: '2px solid',
      fontWeight: 'semiBold',
      svg: {
        mr: '0.5rem',
      },
      modeTypes: {
        light: {
          borderColor: 'alabaster',
          color: 'text',
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          },
        },
        colored: {
          borderColor: 'fuelYellow',
          color: 'ebonyClay',
          backgroundColor: 'fuelYellow',
          '&:hover': {
            backgroundColor: 'texasRose',
          },
        },
        dark: {
          borderColor: 'text',
          color: 'text',
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'mercury',
          },
        },
        transparent: {
          borderColor: 'white',
          color: 'white',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'rgba(238, 238, 238, 0.3)',
          },
        },
        transparentDark: {
          borderColor: 'text',
          color: 'text',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'alabaster',
          },
        },
      },
    },
  },
  icon: {
    backgroundColor: 'transparent',
    border: 0,
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    p: '0.25rem',
    variant: 'transitions.default',
    svg: {
      fontSize: '1rem',
    },
    '&:hover': {
      color: 'black',
    },
  },
  sizes: {
    default: {
      p: 0,
    },
    small: {
      height: '2.25rem',
      fontSize: '0.875rem',
      p: '0.25rem 1.75rem',
    },
    medium: {
      height: '3.0rem',
      p: '0.75rem 2.25rem',
      fontSize: '0.875rem',
    },
    big: {
      p: '0.5rem 3.0rem',
      height: '3.75rem',
      fontSize: '1.0rem',
      minWidth: '11rem',
    },
  },
};
