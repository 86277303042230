import { useEffect, useRef } from 'react';
import { noop } from 'utils/lodash/client';
import { isServer } from 'utils/nextjs';
import { STICKY_HEADER_CLASS } from './consts';

export const useStickyHeader = (): void => {
  const htmlElementRef = useRef(
    isServer() ? null : document.querySelector('html'),
  );
  const getIsStickyValue = (): boolean => {
    const htmlElement = htmlElementRef.current;

    if (!htmlElement || !window) {
      return false;
    }

    return htmlElement.scrollTop - 118 > 0;
  };

  useEffect(() => {
    const htmlElement = htmlElementRef.current;

    if (!htmlElement || !window) {
      return noop;
    }

    const scrollHandler = (): void => {
      const isSticky = getIsStickyValue();

      const header = document.querySelector('header');

      if (!header) {
        return;
      }

      header.classList[isSticky ? 'add' : 'remove'](STICKY_HEADER_CLASS);
    };

    window.addEventListener('scroll', scrollHandler);
    window.addEventListener('touchmove', scrollHandler);

    return (): void => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('touchmove', scrollHandler);
    };
  }, []);
};
