export default {
  title: {
    variant: 'text.heading6',
    mb: '0.75rem',
  },
  heading: {
    variant: 'text.heading6',
    fontWeight: 'semiBold',
    pb: '0.75rem',
    mb: '1rem',
    borderBottom: '1px solid',
    borderColor: 'mercury',
  },
  list: {
    gridTemplateColumns: ['1fr', '', 'repeat(3, 1fr)'],
    gridColumnGap: '2rem',
    gridRowGap: '2rem',
  },
  description: {
    variant: 'text.text',
    mb: '0.75rem',
  },
};
