class FetchError extends Error {
  constructor(message: string, public response: unknown) {
    super(message);
  }
}

const makeRequest = async <T>(url: string, init?: RequestInit): Promise<T> => {
  const response = await fetch(url, init);
  const json = (await response.json()) as T;

  if (!response.ok) {
    const error = new FetchError('Request failed', json);

    throw error;
  }

  return json;
};

export default makeRequest;
