export default {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  boxShadow: 'nearestAtm',
  p: '1rem',
  variant: 'text.typo4',
  image: {
    a: {
      display: 'block',
    },
  },
  content: {
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
  },
  details: {
    flexGrow: 1,
  },
  name: {
    mt: '1rem',
    color: 'black',
    fontWeight: 'semiBold',
  },
  locationAway: {
    display: 'flex',
    alignItems: 'center',
    mt: '2rem',
    mb: '0.625rem',
    svg: {
      color: 'fuelYellow',
      mr: '0.5rem',
    },
  },
  address: {
    display: 'block',
    textDecoration: 'underline',
  },
  action: {
    mt: '2.75rem',
    mb: '0.5rem',
    justifyContent: 'center',
    a: {
      width: '100%',
      maxWidth: '13.125rem',
    },
  },
};
