import { ThemeUICSSObject } from 'theme-ui';

import input from './Input/theme';
import textarea from './Textarea/theme';
import select from './Select/theme';
import checkbox from './Checkboxes/theme';
import datepicker from './Datepicker/theme';

export default {
  base: {
    color: 'text',
    appearance: 'none',
    fontFamily: 'inherit',
    '&::placeholder': {
      opacity: 1,
      color: 'dustyGray',
    },
  },
  input,
  textarea,
  select,
  checkbox,
  datepicker,
  required: {
    fontSize: 'inherit',
    color: 'error',
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelCustom: {
    mb: '0.675rem',
    display: 'block',
    variant: 'text.typo7',
  },
  error: {
    mt: '0.25rem',
    color: 'error',
    textAlign: 'right' as ThemeUICSSObject['textAlign'],
    fontSize: '0.75rem',
    display: 'block',
  },
  sizes: {
    default: {
      variant: 'text.input',
      '&::placeholder': {
        variant: 'text.input',
      },
    },
    big: {
      fontSize: ['1.5rem', '2.25rem'],
      fontWeight: 'regular',
      '&::placeholder': {
        fontSize: ['1.5rem', '2.25rem'],
      },
    },
  },
};
