import media from 'setup/MediaQueries';
import useMedia from './useMedia';

const makeUseIsBreakpointHook = (
  breakpoint: string,
): (() => boolean) => (): boolean =>
  useMedia([breakpoint], [true], false, false);

export const useIsPhone = makeUseIsBreakpointHook(media.phone);

export const useIsTablet = makeUseIsBreakpointHook(media.tablet);

export const useIsDesktop = makeUseIsBreakpointHook(media.desktop);
