import { ThemeUICSSObject } from 'theme-ui';

export default {
  width: '100%',
  listStyle: 'none',
  p: 0,
  m: 0,
  heading: {
    display: 'flex',
    p: 0,
    backgroundColor: 'transparent',
    alignItems: 'center',
    width: '100%',
    fontFamily: 'inherit',
    color: 'currentColor',
    variant: 'transitions.default',
    cursor: 'pointer',
    textAlign: 'left' as ThemeUICSSObject['textAlign'],
  },
  item: {
    width: '100%',
    '&:not(:last-child)': {
      mb: '0.625rem',
    },
  },
  icon: {
    fontSize: '0.875rem',
    ml: '0.75rem',
    variant: 'transitions.default',
    flexShrink: 0,
  },
  sign: {
    position: 'absolute' as ThemeUICSSObject['position'],
    left: 0,
    top: '48%',
    transform: 'translateY(-50%)',
  },
  content: {
    width: '100%',
  },
  modes: {
    default: {},
    bordered: {
      '.heading': {
        justifyContent: 'space-between',
        variant: 'text.text',
        fontWeight: 'semiBold',
        '&:hover': {
          color: 'black',
        },
      },
      '.item': {
        p: '0.625rem 1rem',
        border: '1px solid',
        borderColor: 'mercury',
      },
      '.content': {
        p: '1rem',
      },
    },
    simple: {
      color: 'dustyGray',
      variant: 'text.typo10',
      '.heading': {
        mx: 'auto',
        position: 'relative' as ThemeUICSSObject['position'],
        pl: '0.75rem',
        width: 'auto',
      },
      '.content': {
        pt: '0.25rem',
      },
    },
  },
};
