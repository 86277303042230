import { ThemeUICSSObject } from 'theme-ui';

export default {
  width: '100%',
  '.react-datepicker-wrapper': {
    width: '100%',
  },
  '.input': {
    borderWidth: '0 0 1px 0',
    borderStyle: 'solid',
    borderRadius: 0,
    width: '100%',
    p: '0.375rem 2.75rem 0.375rem 0.375rem',
    backgroundColor: 'transparent',
    position: 'relative' as ThemeUICSSObject['position'],
    zIndex: 1,
    variant: 'forms.base',
    '&:focus': {
      outline: 'none',
      borderColor: 'texasRose',
    },
  },
  icon: {
    position: 'absolute' as ThemeUICSSObject['position'],
    top: '50%',
    right: '1rem',
    transform: 'translateY(-50%)',
    fontSize: '1rem',
  },
};
