import { useState, useEffect } from 'react';

const useIsDesktopDevice = (): boolean => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const newIsDesktop = !/iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isDesktop !== newIsDesktop) {
      setIsDesktop(newIsDesktop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isDesktop;
};

export default useIsDesktopDevice;
