import { Theme } from 'setup/theme';
import { useTheme as useThemeUI } from '@emotion/react';

const useTheme = (): Theme => {
  const theme = useThemeUI();

  return (theme as unknown) as Theme;
};

export default useTheme;
