import styled from '@emotion/styled';
import { Flex } from 'theme-ui';

export const StyledLayoutWrapper = styled(Flex)`
  min-height: 100vh;
  flex-direction: column;
`;

export const StyledLayoutMain = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
`;
