import buyBitcoin from 'components/organisms/Forms/BuyBitcoinForm/theme';
import transfer from 'components/organisms/Forms/TransferForm/theme';
import personalDetails from 'components/organisms/Forms/PersonalDetailsForm/theme';
import contact from 'components/organisms/Forms/ContactForm/theme';
import transactionChecker from 'components/organisms/Forms/TransactionCheckerForm/theme';
import otc from 'components/organisms/Forms/OtcForm/theme';
import { ThemeUICSSObject } from 'theme-ui';

export default {
  card: {
    boxShadow: 'transactionChecker',
    p: '1.25rem',
    borderRadius: '0.5rem',
    borderTop: '8px solid',
    borderColor: 'fuelYellow',
  },
  inner: {
    maxWidth: '40rem',
    mx: 'auto',
  },
  title: {
    variant: 'text.heading4',
  },
  titleAlt: {
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
    variant: 'text.typo1',
    fontWeight: 'semiBold',
  },
  content: {
    gridRowGap: '1.5rem',
  },
  action: {
    mt: '1.25rem',
  },
  actionAlt: {
    justifyContent: 'center',
    variant: 'form.action',
    mb: '2rem',
  },
  required: {
    variant: 'text.text',
    color: 'error',
    mb: ['0.75rem', '', '1.25rem'],
  },
  loader: {
    zIndex: 1,
    position: 'absolute' as ThemeUICSSObject['position'],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  buyBitcoin,
  transfer,
  personalDetails,
  contact,
  transactionChecker,
  otc,
};
