import { ThemeUICSSObject } from 'theme-ui';

export default {
  '&[disabled]': {
    opacity: 0.6,
    pointerEvents: 'none' as ThemeUICSSObject['pointerEvents'],
  },
  borderWidth: '0 0 1px 0',
  borderStyle: 'solid',
  borderRadius: 0,
  width: '100%',
  p: '0.375rem',
  variant: 'forms.base',
  resize: 'none',
  height: '8.5rem',
  fontSize: ['1rem', '0.875rem'],
  '&:focus': {
    outline: 'none',
    borderColor: 'texasRose',
  },
};
