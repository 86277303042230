import { ThemeUICSSObject } from 'theme-ui';

export default {
  '&[disabled]': {
    opacity: 0.6,
    pointerEvents: 'none' as ThemeUICSSObject['pointerEvents'],
  },
  default: {
    variant: 'transitions.default',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: 'text',
    width: '1rem',
    height: '1rem',
    mt: '0.1875rem',
    mr: '0.625rem',
    flexShrink: 0,
    '&::after': {
      backgroundColor: 'transparent',
      width: 0,
      height: 0,
    },
  },
  modes: {
    default: {
      fontSize: '1rem',
    },
    small: {
      textTransform: 'uppercase' as ThemeUICSSObject['textTransform'],
      variant: 'text.typo10',
      '.label': {
        mt: '0.25rem',
      },
    },
  },
  checked: {
    variant: 'forms.checkbox.default',
    '&::after': {
      borderLeft: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'fuelYellow',
      width: '60%',
      height: '30%',
    },
  },
  label: {
    mt: '0.1875rem',
    verticalAlign: 'center',
    fontSize: '0.75rem',
    a: {
      color: 'fuelYellow',
      '&:hover': {
        color: 'texasRose',
      },
    },
  },
};
