import { ThemeUICSSObject } from 'theme-ui';

export default {
  '&[disabled]': {
    opacity: 0.35,
    pointerEvents: 'none' as ThemeUICSSObject['pointerEvents'],
  },
  width: '100%',
  variant: 'forms.base',
  modes: {
    default: {
      p: '0.375rem',
      borderWidth: '0 0 1px 0',
      borderStyle: 'solid',
      borderRadius: 0,
      variant: 'text.input',
      '&:focus': {
        outline: 'none',
        borderColor: 'texasRose',
      },
    },
    dark: {
      p: '0.25rem 1rem',
      borderRadius: 0,
      border: '1px solid',
      borderColor: 'transparent',
      backgroundColor: 'text',
      color: 'white',
      fontSize: '0.875rem',
      fontFamily: 'inherit',
      '&:focus': {
        outline: 'none',
        borderColor: 'texasRose',
      },
      '& + svg': {
        color: 'white',
      },
    },
  },
};
