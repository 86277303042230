export default {
  width: ['100%', '', 'calc(100% + 2.5rem)'],
  margin: ['', '', '0 -1.25rem 2.5rem'],
  variant: 'text.text',
  'p + p': {
    mt: '1rem',
  },
  title: {
    variant: 'text.heading1',
    mb: '0.75rem',
  },
  item: {
    padding: ['', '', '0 1.25rem'],
  },
};
