const commonStyle = {
  p: '0.25rem',
  borderRadius: '0.25em',
  display: 'block',
  border: '1px solid',
  fontSize: '0.75rem',
  fontFamily: 'secondary',
};

export default {
  default: {
    ...commonStyle,
    borderColor: 'dustyGray',
  },
  colored: {
    ...commonStyle,
    borderColor: 'fuelYellow',
  },
};
