import { ThemeUICSSObject } from 'theme-ui';

export default {
  title: {
    textTransform: 'uppercase' as ThemeUICSSObject['textTransform'],
    variant: 'text.typo9',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: ['center', '', 'flex-start'],
    mx: '-0.3125rem',
  },
  item: {
    mx: '0.3125rem',
    mt: '0.125rem',
    mb: '0.25rem',
  },
};
