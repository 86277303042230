import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { transition } from 'utils/mixins';
import { AccordionContentProps } from './types';

export const StyledAccordionWrapper = styled.div<AccordionContentProps>`
  ${transition()}
  overflow: hidden;
  width: 100%;

  ${({ isOpen }) =>
    css`
      max-height: ${isOpen ? '9999px' : '0'};
      opacity: ${isOpen ? 1 : 0};
    `}
`;
