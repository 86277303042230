import { ThemeUICSSObject } from 'theme-ui';

export default {
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center' as ThemeUICSSObject['textAlign'],
  title: {
    mt: '1.25rem',
    mb: '0.625rem',
    variant: 'text.heading6',
  },
  subTitle: {
    fontSize: '2.25rem',
    fontWeight: 'regular',
    mb: '0.675rem',
    '> *': {
      font: 'inherit',
    },
    a: {
      '&:hover': {
        color: 'texasRose',
      },
    },
  },
  description: {
    variant: 'text.text',
    a: {
      font: 'inherit',
      color: 'fuelYellow',
      '&:hover': {
        color: 'texasRose',
      },
    },
  },
};
