import React, { Fragment } from 'react';
import { Flex, Text } from 'theme-ui';

import { HamburgerProps } from './types';

const Hamburger: React.FC<HamburgerProps> = ({ isOpen }) => (
  <Fragment>
    <Flex
      variant={isOpen ? 'hamburger.linesOpen' : 'hamburger.lines'}
      as="span"
    >
      <Flex as="span" />
      <Flex as="span" />
      <Flex as="span" />
    </Flex>
    <Text variant="hamburger.label" as="span">
      Toggle menu
    </Text>
  </Fragment>
);

export default Hamburger;
