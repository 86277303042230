import { isServer } from './nextjs';

export const isStorybook = (): boolean =>
  // eslint-disable-next-line no-underscore-dangle
  isServer() ? false : !!window.__IS_STORYBOOK;

export const enumValues = (
  enumObject: Record<string, string | number>,
): (string | number)[] =>
  Object.entries(enumObject)
    .filter(([key]) => Number.isNaN(Number(key)))
    .map(([, value]) => value);
