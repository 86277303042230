import { ThemeUICSSObject } from '@theme-ui/css';

export default {
  heading0: {
    fontSize: ['2rem', '3.75rem'],
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
  },
  heading1: {
    fontSize: ['2rem', '2.25rem'],
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
  },
  heading2: {
    fontSize: ['1.875rem', '2.125rem'],
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
    fontFamily: 'secondary',
  },
  heading3: {
    fontSize: '2rem',
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
    fontWeight: 'semiBold',
  },
  heading4: {
    fontSize: ['1.625rem', '1.875rem'],
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
  },
  heading5: {
    fontSize: ['1.5rem', '1.625rem'],
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
  },
  heading6: {
    fontSize: ['1.25rem', '1.5rem'],
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
  },
  heading7: {
    fontSize: '1.5rem',
    fontWeight: 'semiBold',
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
  },
  typo1: {
    fontSize: '1.5rem',
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
    fontFamily: 'secondary',
  },
  typo2: {
    fontSize: '1.375rem',
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
  },
  typo3: {
    fontSize: '1.25rem',
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
    fontFamily: 'secondary',
  },
  typo4: {
    fontSize: '1.125rem',
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
  },
  typo5: {
    fontSize: '1.125rem',
    letterSpacing: '-0.03em',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
    fontFamily: 'secondary',
  },
  typo6: {
    fontSize: '1rem',
    letterSpacing: '-0.01em',
    '.fonts-loading &': {
      letterSpacing: '0.03125em',
    },
  },
  typo7: {
    fontSize: '0.875rem',
    letterSpacing: '-0.01em',
    '.fonts-loading &': {
      letterSpacing: '0.03125em',
    },
    fontWeight: 'semiBold',
  },
  typo8: {
    fontSize: '0.875rem',
    letterSpacing: '-0.01em',
    '.fonts-loading &': {
      letterSpacing: '0.03125em',
    },
    fontFamily: 'secondary',
  },
  typo9: {
    fontSize: '0.75rem',
    fontWeight: 'semiBold',
    letterSpacing: '-0.01em',
    '.fonts-loading &': {
      letterSpacing: '0.03125em',
    },
    fontFamily: 'secondary',
  },
  typo10: {
    fontSize: '0.75rem',
    letterSpacing: '-0.01em',
    '.fonts-loading &': {
      letterSpacing: '0.03125em',
    },
    fontFamily: 'secondary',
  },
  typo11: {
    fontSize: '1rem',
    letterSpacing: '-0.01em',
    '.fonts-loading &': {
      letterSpacing: '0.03125em',
    },
    fontFamily: 'secondary',
  },
  typo12: {
    fontSize: '1rem',
    fontWeight: 'semiBold',
    letterSpacing: '-0.01em',
    '.fonts-loading &': {
      letterSpacing: '0.03125em',
    },
    fontFamily: 'secondary',
  },
  text: {
    fontSize: '1rem',
    letterSpacing: '-0.01em',
    '.fonts-loading &': {
      letterSpacing: '0.03125em',
    },
  },
  input: {
    fontSize: ['1rem', '0.875rem'],
    letterSpacing: '-0.01em',
    '.fonts-loading &': {
      letterSpacing: '0.03125em',
    },
  },
  hidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute' as ThemeUICSSObject['position'],
    whiteSpace: 'nowrap',
    width: '1px',
  },
};
