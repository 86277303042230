import { ThemeUICSSObject } from 'theme-ui';

export default {
  list: {
    overflow: 'hidden',
  },
  button: {
    borderRadius: 0,
    borderBottom: '4px solid',
    width: '100%',
    color: 'abbey',
    fontFamily: 'inherit',
    background: 'transparent',
    cursor: 'pointer',
    p: ['0.5rem 0.625rem', '0.5rem 0.75rem', '1rem'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
    variant: 'transitions.default',
    fontSize: '1.125rem',
    '&:hover': {
      color: 'black',
    },
  },
  content: {
    my: ['1.5rem', '', '2.5rem'],
  },
};
