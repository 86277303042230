import { ThemeUICSSObject } from 'theme-ui';
import { ATMS_LIST_HEIGHT } from './consts';

export default {
  item: {
    borderBottom: '1px solid',
    borderColor: 'mercury',
  },
  tile: {
    position: 'relative' as ThemeUICSSObject['position'],
    fontFamily: 'secondary',
    variant: 'transitions.default',
    height: '100%',
    '&:hover': {
      backgroundColor: 'alabaster',
    },
  },
  button: {
    p: 0,
    zIndex: 1,
    display: 'block',
    cursor: 'pointer',
    position: 'absolute' as ThemeUICSSObject['position'],
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  content: {
    position: 'relative' as ThemeUICSSObject['position'],
    gridColumnGap: 0,
    gridTemplateColumns: [
      '1fr minmax(4.25rem, auto)',
      '1fr minmax(6.5rem, auto)',
    ],
    height: '100%',
    alignContent: 'center',
    p: '1rem',
    a: {
      position: 'relative' as ThemeUICSSObject['position'],
      zIndex: 1,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  contentSell: {
    position: 'relative' as ThemeUICSSObject['position'],
    gridColumnGap: '1rem',
    gridTemplateColumns: [
      '1fr minmax(4.25rem, auto)',
      '1fr minmax(6.5rem, auto)',
    ],
    height: '100%',
    alignItems: 'center',
    p: '1rem',
    a: {
      position: 'relative' as ThemeUICSSObject['position'],
      zIndex: 1,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingRight: '1rem',
      borderRight: '1px solid',
      borderColor: 'mercury',
      minHeight: `calc(${ATMS_LIST_HEIGHT / 4}px - 2rem)`,
    },
  },
  location: {
    fontSize: '0.875rem',
    variant: 'text.typo8',
  },
  atmLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
    color: 'candleLight',
    pl: '1rem',
    ml: '1rem',
    borderLeft: '1px solid',
    borderColor: 'mercury',
    span: {
      mt: '0.25rem',
      fontSize: '0.75rem',
      fontWeight: 'semiBold',
    },
    '&:hover': {
      color: 'text',
    },
  },
};
