export default {
  title: {
    variant: 'text.heading4',
    mb: '0.75rem',
  },
  description: {
    variant: 'text.text',
    strong: {
      fontWeight: 'semiBold',
    },
    ol: {
      pl: '1.25rem',
      listStyleType: 'decimal',
    },
    li: {
      mb: '0.125rem',
    },
    small: {
      display: 'block',
      mt: '1rem',
      fontSize: '0.75rem',
    },
  },
  content: {
    mt: '2rem',
  },
  media: {
    alignSelf: 'center',
  },
  grid: {
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
    justifyContent: ['', '', 'space-between'],
    gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', '37.5rem 28rem'],
  },
};
