import React, { Fragment, useCallback } from 'react';
import { Box, Button, Flex } from 'theme-ui';

import useToggle from 'hooks/useToggle';
import { IconArrowDown, IconMinus, IconPlus } from 'components/styles/Icons';
import useTheme from 'hooks/useTheme';
import {
  AccordionItemProps,
  AccordionMode,
  AccordionProps,
  ContentComponentAccordionItem,
} from './types';
import { StyledAccordionWrapper } from './styles';

const renderIcon = (mode: AccordionMode, isOpen: boolean) => {
  if (mode === AccordionMode.Default) {
    return (
      <Flex
        as="span"
        variant="accordion.icon"
        sx={{ transform: isOpen ? 'rotate(180deg)' : undefined }}
      >
        <IconArrowDown />
      </Flex>
    );
  }

  if (mode === AccordionMode.Bordered) {
    return (
      <Flex as="span" variant="accordion.icon">
        {isOpen ? <IconMinus /> : <IconPlus />}
      </Flex>
    );
  }

  return (
    <Flex as="span" variant="accordion.sign">
      {isOpen ? '-' : '+'}
    </Flex>
  );
};

const DefaultAccordionContentComponent: React.FC<{
  item: ContentComponentAccordionItem;
}> = ({ item: { content } }) => <Fragment>{content}</Fragment>;

const AccordionItem: React.FC<AccordionItemProps> = ({
  item,
  mode = AccordionMode.Default,
  contentComponent,
}) => {
  const ContentComponent = contentComponent;
  const [isOpen, { toggle }] = useToggle({
    defaultToggleState: item.initialState,
    toggleOnRouteChange: false,
  });

  const toggleItem = useCallback(() => {
    toggle();

    if (item.onToggle) {
      item.onToggle(!isOpen);
    }
  }, [isOpen, item, toggle]);

  return (
    <Box>
      <Button
        className="heading"
        variant="accordion.heading"
        aria-label={isOpen ? 'Close accordion' : 'Open accordion'}
        aria-expanded={isOpen}
        onClick={toggleItem}
        type="button"
      >
        {item.heading}
        {renderIcon(mode, isOpen)}
      </Button>
      <StyledAccordionWrapper {...{ isOpen }}>
        {isOpen && (
          <Box variant="accordion.content" className="content">
            <ContentComponent {...{ item }} />
          </Box>
        )}
      </StyledAccordionWrapper>
    </Box>
  );
};

const Accordion: React.FC<AccordionProps> = ({
  items,
  mode = AccordionMode.Default,
  contentComponent = DefaultAccordionContentComponent,
  makeItemId,
}) => {
  const { modes, ...rest } = useTheme().accordion;

  return (
    <Box as="ul" variant="accordion" sx={{ ...rest, ...modes[mode] }}>
      {items.map((item) => (
        <Box
          as="li"
          key={item.key}
          id={makeItemId ? makeItemId(item) : undefined}
          variant="accordion.item"
          className="item"
        >
          <AccordionItem {...{ mode, item, contentComponent }} />
        </Box>
      ))}
    </Box>
  );
};

export { AccordionItem as AccordionItemComponent, Accordion as default };
