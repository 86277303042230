import { ThemeUICSSObject } from 'theme-ui';

export default {
  width: '100%',
  justifySelf: 'center',
  maxWidth: '20rem',
  '.react_lightgallery_item': {
    width: '100%',
  },
  '.swiper-container': {
    display: 'flex',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  '.swiper-wrapper': {
    display: 'flex',
    alignItems: 'center',
  },
  '.swiper-slide': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
    variant: 'transitions.default',
    img: {
      mx: 'auto',
    },
    '&:hover': {
      opacity: 0.65,
    },
  },
  icon: {
    position: 'absolute' as ThemeUICSSObject['position'],
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    width: '2.25rem',
    height: '100%',
    fontSize: '1rem',
    p: 0,
    variant: 'transitions.default',
    color: 'text',
    '&:hover': {
      color: 'abbey',
    },
    svg: {
      fontSize: '1.25rem',
    },
    '&.swiper-button-disabled': {
      opacity: 0,
      pointerEvents: 'none' as ThemeUICSSObject['pointerEvents'],
    },
    '&.swiper-button-prev': {
      left: '-1rem',
    },
    '&.swiper-button-next': {
      right: '-1rem',
    },
  },
  thumbs: {
    position: 'relative' as ThemeUICSSObject['position'],
    mt: '1rem',
    '.swiper-container': {
      mx: '2.25rem',
    },
    img: {
      maxHeight: '5rem',
    },
  },
  main: {
    height: '26.875rem',
  },
};
