const LOCALES = {
  ca: 'ca',
  fr: 'fr',
  us: 'us',
};

const i18n = {
  locales: [LOCALES.ca, LOCALES.us, LOCALES.fr],
  defaultLocale: LOCALES.ca,
  localeDetection: false,
};

module.exports = {
  LOCALES,
  i18n,
};
