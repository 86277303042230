import urljoin from 'url-join';
import { getConfig } from 'utils/config';
import { LOCALES } from '../../../config/next/languages';

const { sellWidgetBasePath } = getConfig().publicRuntimeConfig;

const routes = {
  home: '/',
  howToBuyBitcoin: '/how-to-buy-bitcoin/',
  howToSellBitcoin: '/how-to-sell-bitcoin/',
  hostBitcoinAtm: '/host-bitcoin-atm/',
  bitcoinATM: '/bitcoin-atm/',
  bitcoinATMToronto: '/bitcoin-atm-toronto/',
  about: '/about/',
  careers: '/careers/',
  press: '/press/',
  partnerships: '/partnerships/',
  compliancePolicy: '/compliance-policy/',
  privacyPolicy: '/privacy-policy/',
  termsService: (locale: string): string =>
    ({
      [LOCALES.fr]: `/${LOCALES.fr}/conditions-dutilisation/`,
    }[locale] || '/terms-service/'),
  news: '/news/',
  contact: '/contact/',
  howToBuyEthereum: '/how-to-buy-ethereum/',
  howToBuyLitecoin: '/how-to-buy-litecoin/',
  eTransferBuy: '/e-transfer-buy/',
  flexepin: '/flexepin-v3/',
  verifyTransaction: '/verify-transaction/',
  fees: '/fees/',
  faq: '/faq/',
  buyCreditCard: '/buy-credit-card/',
  state: (stateSlug: string): string => `/bitcoin-atm/${stateSlug}/`,
  city: (stateSlug: string, citySlug: string): string =>
    `/bitcoin-atm/${stateSlug}/${citySlug}/`,
  atmDetails: (stateSlug: string, citySlug: string, atmSlug: string): string =>
    `/bitcoin-atm/${stateSlug}/${citySlug}/${atmSlug}/`,
  newsPage: (page: number): string => `/news/page/${page}/`,
  newsDetails: (slug: string): string => urljoin('/news/', slug, '/'),
  newsCategory: (slug: string): string => urljoin('/news/category/', slug, '/'),
  sell: '/sell/',
  sellForm: (id: string): string => `${sellWidgetBasePath}/${id}/`,
  sellOrder: `${sellWidgetBasePath}/order/`,
};

export default routes;
