import React from 'react';
import { Button } from 'theme-ui';
import { HeaderMobileButtonProps } from './types';

const MobileButton: React.FC<HeaderMobileButtonProps> = ({
  children,
  ...props
}) => (
  <Button variant="header.mobileButton" {...props}>
    {children}
  </Button>
);

export default MobileButton;
