import { ThemeUICSSObject } from 'theme-ui';

export default {
  position: 'absolute' as ThemeUICSSObject['position'],
  width: '0.125rem',
  height: '0.125rem',
  display: 'block',
  left: 0,
  top: [0, '', '-3.75rem'],
  opacity: 0,
  overflow: 'hidden',
  visibility: 'hidden',
};
