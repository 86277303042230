import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Flex, Text } from 'theme-ui';

import media from 'setup/MediaQueries';
import { StyledSelectLocationProps } from './types';

const locationWrapperStyle = () =>
  css`
    position: absolute;
    top: 100%;
    left: -0.75rem;
    width: 10rem;

    @media ${media.phone} {
      left: auto;
      right: -0.75rem;
    }

    @media ${media.desktop} {
      left: -0.75rem;
      right: auto;
    }
  `;

export const StyledLocationMobileWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  ${locationWrapperStyle}
`;

export const StyledLocationWrapper = styled(Flex)`
  transition: transform 0.25s ease-out, opacity 0.125s ease-out;
  max-height: 0;
  transform: translate3d(0, 0.625rem, 0);
  opacity: 0;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.white};
  ${locationWrapperStyle}
`;

export const StyledLocationText = styled(Text)<StyledSelectLocationProps>`
  display: flex;
  align-items: center;

  svg {
    font-size: inherit;
    margin-right: 0.25rem;
  }

  ${({ isDropdown }) =>
    isDropdown
      ? css`
          margin-right: 0.75rem;
          svg {
            margin-bottom: 0.125rem;
          }
        `
      : css`
          justify-content: center;
          margin-bottom: 0.25rem;
        `}
`;

export const StyledLocationDropdown = styled(Flex)`
  &:hover {
    .location-wrapper {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      max-height: 999px;
      pointer-events: auto;
    }
  }
`;
