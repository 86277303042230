import React, { Fragment } from 'react';
import { Box, Button, Flex } from 'theme-ui';
import { useRouter } from 'next/router';

import Link from 'components/atoms/Link';
import Image from 'components/atoms/Image';
import { IconPin } from 'components/styles/Icons';
import { LinkVariant } from 'components/atoms/Link/types';
import useToggle from 'hooks/useToggle';
import routes from 'setup/consts/routes';
import useIsDesktopDevice from 'hooks/useIsDesktopDevice';
import { SelectLocationProps } from './types';
import {
  StyledLocationDropdown,
  StyledLocationMobileWrapper,
  StyledLocationText,
  StyledLocationWrapper,
} from './styles';

const SelectLocation: React.FC<SelectLocationProps> = ({
  items,
  isDropdown,
  toggleRef,
}) => {
  const isDesktopDevice = useIsDesktopDevice();
  const router = useRouter();
  const selectedDropdownItem = items.find(
    ({ locale }) => locale === router.locale,
  );
  const dropdownItems = items.filter(({ locale }) => locale !== router.locale);
  const [
    isOpen,
    { toggleOn, registerContainerRef: selectLocationRef },
  ] = useToggle();

  const dropdownComponent = (
    <Box as="ul" variant="selectLocation.dropdownList">
      {dropdownItems.map(({ key, label, href, src, alt, locale }) => (
        <Box {...{ key }} as="li" variant="selectLocation.dropdownItem">
          <Link variant={LinkVariant.Default} {...{ href, locale }}>
            <Box variant="selectLocation.image">
              <Image {...{ src, alt }} width={18} height={12} lazy={false} />
            </Box>
            {label}
          </Link>
        </Box>
      ))}
    </Box>
  );

  toggleRef?.(toggleOn);

  const linkComponent = (
    <Link
      variant={LinkVariant.Default}
      href={selectedDropdownItem?.href || routes.home}
    >
      {selectedDropdownItem?.src && (
        <Box variant="selectLocation.image">
          <Image
            src={selectedDropdownItem.src}
            alt={selectedDropdownItem?.alt}
            width={18}
            height={12}
            lazy={false}
          />
        </Box>
      )}
      {selectedDropdownItem?.label}
    </Link>
  );

  return (
    <Flex
      variant="selectLocation"
      sx={{
        flexDirection: isDropdown ? 'row' : 'column',
        color: isDropdown ? 'black' : 'text',
      }}
    >
      <StyledLocationText
        {...{ isDropdown }}
        as="p"
        variant={isDropdown ? 'typo10' : 'typo7'}
      >
        <IconPin /> Select Location{isDropdown ? ':' : ''}
      </StyledLocationText>
      {isDropdown ? (
        <Fragment>
          {isDesktopDevice ? (
            <StyledLocationDropdown variant="selectLocation.dropdown">
              {linkComponent}
              <StyledLocationWrapper
                className="location-wrapper"
                variant="selectLocation.dropdownWrapper"
              >
                {dropdownComponent}
              </StyledLocationWrapper>
            </StyledLocationDropdown>
          ) : (
            <Fragment>
              {isOpen ? (
                <Flex variant="selectLocation.dropdown">
                  {linkComponent}
                  <StyledLocationMobileWrapper
                    ref={selectLocationRef}
                    variant="selectLocation.dropdownWrapper"
                  >
                    {dropdownComponent}
                  </StyledLocationMobileWrapper>
                </Flex>
              ) : (
                <Flex variant="selectLocation.dropdown" onClick={toggleOn}>
                  <Button
                    id="locale-select-button"
                    type="button"
                    variant="selectLocation.dropdown.buttonLink"
                  >
                    <Box variant="selectLocation.image">
                      {selectedDropdownItem?.src && (
                        <Image
                          src={selectedDropdownItem.src}
                          alt={selectedDropdownItem.alt}
                          width={18}
                          height={12}
                          lazy={false}
                        />
                      )}
                    </Box>
                    {selectedDropdownItem?.label}
                  </Button>
                </Flex>
              )}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <Flex as="ul" variant="selectLocation.list">
          {items.map(({ key, label, href, src, alt, locale }) => (
            <Box {...{ key }} as="li" variant="selectLocation.item">
              <Link {...{ href, locale }}>
                <Box as="span" variant="selectLocation.image">
                  <Image
                    {...{ src, alt }}
                    width={18}
                    height={12}
                    lazy={false}
                  />
                </Box>
                {label}
              </Link>
            </Box>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default SelectLocation;
