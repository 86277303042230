import { ThemeUICSSObject } from 'theme-ui';

export default {
  gridTemplateColumns: ['1fr', '', 'repeat(2, 1fr)'],
  gridGap: '2rem',
  main: {
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
    a: {
      color: 'fuelYellow',
    },
  },
  action: {
    alignItems: 'center',
    justifyContent: 'center',
  },
};
